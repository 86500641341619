@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;500;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*:focus {
  outline: none !important;
}

body {
  margin: 0;

  font-family: 'Open Sans', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fef7f3;
  overflow-y: scroll;
}

a {
  text-decoration: none;
}

p {
  font-size: 1.1rem;
  line-height: 1.4;
  margin-bottom: 1.5rem;
}

input,
textarea {
  font-family: 'Open Sans', sans-serif;
  font-size: 1.2rem;
  border: none;
  border-bottom: 1px solid #ccc;
  outline: none;
  padding: 0.5rem;
  width: 100%;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

h3 {
  margin-bottom: 10px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
